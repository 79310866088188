import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticStyle:{"margin-bottom":"15px","align-items":"center","display":"flex"},on:{"click":function($event){_vm.disabled_lining_show = false}}},[_vm._m(0),_c('div',{staticClass:"control"},[_c(VSwitch,{attrs:{"disabled":_vm.disabled_lining_show},model:{value:(_vm.item.lining_show),callback:function ($$v) {_vm.$set(_vm.item, "lining_show", $$v)},expression:"item.lining_show"}})],1)]),(_vm.item.lining_show)?_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_vm._m(1),_c('div',{staticClass:"control"},[_c(VMenu,{attrs:{"top":"","z-index":"130","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"custom__menu--color",style:(("background-color:" + (_vm.item.lining_global_color)))},on))]}}],null,false,84259350),model:{value:(_vm.lining_show),callback:function ($$v) {_vm.lining_show=$$v},expression:"lining_show"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},model:{value:(_vm.item.lining_global_color),callback:function ($$v) {_vm.$set(_vm.item, "lining_global_color", $$v)},expression:"item.lining_global_color"}})],1)],1)],1)],1)]):_vm._e()]),(_vm.item.lining_show)?_c('div',[_c('div',{staticStyle:{"margin-bottom":"15px","display":"flex","align-items":"center"}},[_vm._m(2),_c('div',{staticClass:"control"},[_c('vue-dropzone',{ref:"upload",staticStyle:{"margin-bottom":"15px"},attrs:{"id":"upload","options":_vm.dropzoneOptions,"destroyDropzone":false},on:{"vdropzone-mounted":_vm.mountDropzone,"vdropzone-sending":_vm.customEvent,"vdropzone-removed-file":_vm.handleRemove,"vdropzone-success":_vm.handleSuccess}})],1)]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"width":"250px","margin-bottom":"0"}},[_vm._v(" Border Radius "),_c('b',[_vm._v(_vm._s(_vm.item.lining_global_border_radius)+"px")])]),_c('div',{staticClass:"control",staticStyle:{"width":"75%"}},[_c('div',{staticClass:"slider"},[_c(VSlider,{attrs:{"hide-details":"","max":50,"min":0},model:{value:(_vm.item.lining_global_border_radius),callback:function ($$v) {_vm.$set(_vm.item, "lining_global_border_radius", $$v)},expression:"item.lining_global_border_radius"}})],1)])])]):_vm._e(),_c('PopupCrop',{ref:"PopupCrop",attrs:{"title":"Change image before upload?"},on:{"confirm":_vm.openEditor,"save":_vm.saveImg,"close":_vm.closeCrop}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label",staticStyle:{"width":"250px"}},[_vm._v("Active Custom Lining")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label",staticStyle:{"width":"250px","margin-bottom":"0"}},[_vm._v(" Background Color ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label",staticStyle:{"margin-bottom":"0","width":"250px"}},[_vm._v("Background Image")])])}]

export { render, staticRenderFns }